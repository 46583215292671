// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dq5JR1GJoOaEvfWYV2Ce{display:flex;flex-direction:column;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./src/components/Layout.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".progress-bar {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress-bar": "Dq5JR1GJoOaEvfWYV2Ce"
};
export default ___CSS_LOADER_EXPORT___;
