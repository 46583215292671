import React, { useContext } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import styles from './Layout.module.scss'
import {
  SetupAndOnboardingViewContainer,
  UiTheme,
  MultiUiBrandProvider,
  ProgressIndicator
} from '@jarvis/react-setup-and-onboarding-addons'
const Layout = ({ children }) => {
  const { rootProps, isHpx } = useContext(ConfigContext)

  return (
    <MultiUiBrandProvider uiBrand={isHpx ? UiTheme.hpx : UiTheme.hpsmart}>
      <SetupAndOnboardingViewContainer>
        {children ? (
          children(rootProps)
        ) : (
          <div className={styles['progress-bar']}>
            <ProgressIndicator
              data-testid="progress"
              size={isHpx ? 'large' : 'small'}
              appearance="circular"
              behavior="indeterminate"
            />
          </div>
        )}
      </SetupAndOnboardingViewContainer>
    </MultiUiBrandProvider>
  )
}

export default Layout
